@import '~app/mixins';

.services {
  display: flex;
  padding: 15px 0 0 40px;

  .item {
    margin-right: 40px;

    .image {
      height: 220px;

      &.logo {
        border-top: 4px solid #3c3e4a;
        border-bottom: 4px solid #3c3e4a;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;

        img {
          max-height: 50%;
          max-width: 100%;
        }
      }

      &:not(.logo) {
        border: 1px solid #3c3e4a;
        background-size: cover;
        background-position: center;
      }
    }

    .content {
      background-color: #e7eaef;
      display: flex;
      height: 1000px;

      .inner {
        padding: 30px;
        display: flex;
        flex-direction: column;
      }

      .center {
        text-align: center;
      }

      .title {
        font-weight: bold;
        font-size: 18px;
      }

      .description {
        flex: 1;
        font-size: 14px;
        line-height: 23px;
      }

      .lists {
        label {
          margin: 20px 0 6px;
          text-align: left;
        }

        .price {
          margin: 30px 0;
          display: flex;
          justify-content: center;
          text-align: center;

          > div {
            > div:first-child {
              display: flex;
              align-items: flex-start;
              font-weight: bold;

              > div:first-child {
                font-size: 25px;
              }

              > div:last-child {
                font-size: 14px;
                margin-top: 2px;
              }
            }
          }
        }

        .message {
          font-weight: bold;
          text-align: center;
          margin: 10px 0;
          color: $color-red;

          &.success {
            color: $color-green;
          }
        }
      }

      .redBox {
        border: 2px solid #e64f15;
        padding: 0 30px 20px;
        text-align: center;

        .lists {
          .price {
            margin: 12px 0;
          }
        }
      }

      ul {
        padding: 0 0 0 16px;
        margin: 0;
      }

      button {
        width: 75%;
        margin: 0 auto;
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.3;
    }

    &.narrow {
      width: 405px;
      min-width: 405px;

      .content {
        flex-direction: column;
      }
    }

    &.listAutomator {
      width: auto;

      .content {
        display: flex;
        flex-direction: row;

        > div {
          margin-right: 30px;

          .left {
            display: flex;
            justify-content: space-around;

            .inner {
              &:first-child {
                width: 370px;
              }

              &:last-child {
                width: 350px;
              }
            }
          }

          .right {
            display: flex;
            justify-content: space-around;

            .inner {
              width: 380px;
            }
          }

          .inner {
            padding: 0;
          }
        }
      }
    }
  }
}
